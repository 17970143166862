
import { defineComponent } from "vue";
import { pageData, pageDataMap } from "../interface/index";
import Error from "./Error.vue";

export default defineComponent({
  name: "Table",
  components: {
    Error,
  },
  data() {
    return {
      pageData: {
        id: "",
        image: "",
        name: "",
        symbol: "",
        currentPrice: 0,
        high24Price: 0,
        low24Price: 0,
      } as pageData,
      pageNumber: 1,
      loading: true,
      pageLoadError: undefined as string | undefined,
      pageLoadStatus: 0,
    };
  },
  async mounted() {
    await this.getPageData(this.pageNumber);
  },
  methods: {
    async getPageData(page: number) {
      try {
        this.loading = true;
        const response = await this.axios.get(
          `coins/markets?vs_currency=eur&order=market_cap_desc&per_page=10&page=${page}&sparkline=false`
        );

        this.pageData = response.data.map((value: pageDataMap) => {
          return {
            id: value.id,
            image: value.image,
            name: value.name,
            symbol: value.symbol,
            currentPrice: value.current_price,
            high24Price: value.high_24h,
            low24Price: value.low_24h,
          };
        });

        this.loading = false;
      } catch (error) {
        this.loading = false;

        this.pageLoadStatus = error.response.status;
        this.pageLoadError = error.response.data.error;
      }
    },
    loadToken(id: string) {
      this.$router.push(`/token/${id}`);
    },
  },
});
