
import { defineComponent } from "vue";
import Table from "@/components/Table.vue";

export default defineComponent({
  name: "Home",
  components: {
    Table,
  },
});
